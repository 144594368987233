<template>
  <div>
    <v-text-field
      v-model="item.name"
      placeholder=" "
      label="Name"
      class="mb-3"
      :autofocus="sIndex === focused"
      v-on:keyup.esc="$emit('enterfunction')"
      v-on:keyup.enter="$emit('enterfunction')"
      @change="saveMessage"
      required
      :rules="[(v) => !!v || 'Name is required']"
    />
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    sIndex: {
      type: Number,
      required: true,
    },
    focused: {
      type: Number,
      required: true,
    },
  },
  methods: {
    saveMessage() {
      this.$store.dispatch('notification/add', {
        type: 'success',
        message: 'Saved successfully',
      });
    },
  },
};
</script>
